import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import etLocale from "date-fns/locale/et"
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Table,
  TableContainer,
  TableBody,
  CssBaseline,
  TableHead,
  TableCell,
  TableRow,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ListItemText,
  ListItemIcon,
  Breadcrumbs,
  Snackbar,
  Link,
  Dialog,
  Paper,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/AddBox';
import DoneIcon from '@material-ui/icons/CheckCircleOutlined';
import EmailIcon from '@material-ui/icons/Email';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory
} from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import { createGlobalState } from 'react-hooks-global-state';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const SELECTED_COMPANY_GLOBAL_STATE_KEY = 'selectedCompany'
const USER_DATA_GLOBAL_STATE_KEY = 'userData'
const INVOICES_FILTER_DATA_GLOBAL_STATE_KEY = 'invoicesFilter'


const LAST_SELECTED_COMPANY_ID_LOCAL_STORAGE_KEY = 'lastSelectedCompanyId'


const EST_INVOICE_TYPE = 1
const FIN_INVOICE_TYPE = 2
const EST_INVOICE_TYPE_2 = 3

const initialGlobalState = {}
initialGlobalState[SELECTED_COMPANY_GLOBAL_STATE_KEY] = null
initialGlobalState[USER_DATA_GLOBAL_STATE_KEY] = null
const defaultYearFilter = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  if (currentDate.getMonth() === 0) {
    return [year - 1, year]
  } else {
    return [year]
  }
}
initialGlobalState[INVOICES_FILTER_DATA_GLOBAL_STATE_KEY] = { years: defaultYearFilter() }
const { useGlobalState } = createGlobalState(initialGlobalState);

const axios = require('axios').default;
const serverUrlPrefix = (window.location.hostname == 'stg.arvemasin.com' ? 'https://stg.arvemasin.com/invobe' : process.env.REACT_APP_SERVER_URL_PREFIX) + '/'
const appUrlPrefix = process.env.PUBLIC_URL + '/'

function saveLastSelectedCompanyToLocalStorage(companyId) {
  localStorage.setItem(LAST_SELECTED_COMPANY_ID_LOCAL_STORAGE_KEY, companyId)
}


class MoneyAmount {

  constructor(euros, cents) {
    this.euros = euros
    if (cents > 99) {
      throw new Error('Invalid cents value: ' + cents + ', max allowed = 99')
    }
    this.cents = cents
  }

  static ZERO() {
    return new MoneyAmount(0, 0)
  }

  toString() {
    return this.euros + '.' + (this.cents < 10 ? '0' : '') + this.cents
  }

  add(other) {
    return MoneyAmount.fixCents(other.euros + this.euros, other.cents + this.cents)
  }

  findVATAmount(vatPercentage) {
    return MoneyAmount.fixCents(0, Math.round((this.euros * 100 + this.cents) * vatPercentage / 100.0))
  }

  times(other) {
    const cents = Math.round(((this.euros * 100 + this.cents) * (other.euros * 100 + other.cents)) / 100)
    return MoneyAmount.fixCents(0, cents)
  }

  minusOrToZero(other) {
    if (other.euros * 100 + other.cents > this.euros * 100 + this.cents) {
      return MoneyAmount.ZERO()
    } else if (this.cents < other.cents) {
      return MoneyAmount.fixCents(this.euros - other.euros - 1, 100 - other.cents + this.cents)
    } else {
      return MoneyAmount.fixCents(this.euros - other.euros, this.cents - other.cents)
    }
  }

  static fixCents(euros, cents) {
    const centsOut = cents % 100
    while (cents >= 100) {
      euros++
      cents -= 100
    }
    return new MoneyAmount(euros + (cents - centsOut) / 100, centsOut)
  }

  static parse(text) {
    if (text.length === 0) {
      return new MoneyAmount(0, 0)
    }
    var separPos = text.indexOf('.')
    if (separPos === -1 && (text.match(/,/g) || []).length === 1) {
      separPos = text.indexOf(',')
    }
    if (separPos > -1) {
      const fullPart = MoneyAmount.toIntIgnoringDecorations(text.substr(0, separPos), false)
      const commaPart = MoneyAmount.toIntIgnoringDecorations(text.substr(separPos + 1), true)
      if (!isNaN(fullPart) && !isNaN(commaPart) && commaPart < 100) {
        return new MoneyAmount(fullPart, commaPart)
      }
    } else {
      const fullPart = MoneyAmount.toIntIgnoringDecorations(text, false)
      if (!isNaN(fullPart)) {
        return new MoneyAmount(fullPart, 0)
      }
    }
    return NaN
  }

  static toIntIgnoringDecorations(text, isCents) {
    text = text.replace(/,/g, '')
    text = text.replace(/ /g, '')
    if (text.length === 0) {
      return 0
    }
    const val = parseInt(text)
    if (val >= 0) {
      const valAsText = val.toString()
      if (isCents) {
        if (valAsText === text) {
          if (val < 10) {
            return 10 * val
          } else {
            return val
          }
        } else if ('0' + valAsText === text && text.length === 2) {
          return val
        }
      } else if (valAsText === text) {
        return val
      }
    }
    return NaN
  }
}

function openAppUrl(history, url) {
  history.push(appUrlPrefix + url)
}

function openInvoiceCopy(history, companyId, id) {
  openAppUrl(history, 'invoice/' + companyId + '/copy' + id)
}

function openInvoicePage(history, companyId, id) {
  openAppUrl(history, 'invoice/' + companyId + '/' + id)
}

function InvoiceRow(props) {
  const invoiceType = parseInt(props.invoiceType)
  const rowData = props.rowData
  const isTotalError = rowData.totalText.length > 0 && isNaN(rowData.total)
  const isAmountError = rowData.amountText.length > 0 && isNaN(rowData.amount)
  const isItemPriceError = rowData.itemPriceText.length > 0 && isNaN(rowData.itemPrice)
  const isDiscountError = rowData.discountText.length > 0 && isNaN(rowData.discount)
  const rowNumber = props.rowNumber
  const descWidth = invoiceType === EST_INVOICE_TYPE ? '60%' : '30%'

  return (
    <TableRow>
      {invoiceType === EST_INVOICE_TYPE && <TableCell>
        <TextField
          id={rowNumber + 'code'}
          label="Kauba kood" variant="filled"
          autoComplete="off"
          value={rowData.code}
          onChange={(e) => props.onDataUpdate({ code: e.target.value })}
        />
      </TableCell>}
      <TableCell style={{ width: descWidth }}>
        <TextField style={{ width: "100%" }}
                   id={rowNumber + 'description'}
                   label="Kauba nimetus" variant="filled"
                   value={rowData.description}
                   onChange={(e) => props.onDataUpdate({ description: e.target.value })}
        />
      </TableCell>
      {invoiceType === EST_INVOICE_TYPE && <TableCell>
        <TextField
          id={rowNumber + 'total'}
          label="Summa" variant="filled"
          autoComplete="off"
          error={isTotalError} helperText={isTotalError ? 'Palun sisestage summa' : null}
          value={rowData.totalText}
          onChange={(e) => props.onDataUpdate({ totalText: e.target.value })}
        />
      </TableCell>}
      {(invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) && <TableCell>
        <TextField
          id={rowNumber + 'amount'}
          label="Kogus" variant="filled"
          autoComplete="off"
          error={isAmountError} helperText={isAmountError ? 'Palun sisestage kogus' : null}
          value={rowData.amountText}
          onChange={(e) => props.onDataUpdate({ amountText: e.target.value })}
        />
      </TableCell>}
      {(invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) && <TableCell>
        <TextField
          id={rowNumber + 'unit'}
          label="Ühik" variant="filled"
          autoComplete="on"
          value={rowData.unit}
          onChange={(e) => props.onDataUpdate({ unit: e.target.value })}
        />
      </TableCell>}
      {(invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) && <TableCell>
        <TextField
          id={rowNumber + 'itemPrice'}
          label="Tüki hind" variant="filled"
          autoComplete="off"
          error={isItemPriceError} helperText={isItemPriceError ? 'Palun sisestage tüki hind' : null}
          value={rowData.itemPriceText}
          onChange={(e) => props.onDataUpdate({ itemPriceText: e.target.value })}
        />
      </TableCell>}
      {invoiceType === FIN_INVOICE_TYPE && <TableCell>
        <TextField
          id={rowNumber + 'discount'}
          label="Soodustus%" variant="filled"
          autoComplete="off"
          error={isDiscountError} helperText={isDiscountError ? 'Palun sisestage soodus' : null}
          value={rowData.discountText}
          onChange={(e) => props.onDataUpdate({ discountText: e.target.value })}
        />
      </TableCell>}
      {(invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) && <TableCell align="right">
        {rowData.totalText}
      </TableCell>}
      <TableCell>
        <IconButton aria-label="delete" onClick={props.removeRow}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const invoiceNormalizer = (data) => {
  return {
    ...data,
    invoice_date: new Date(data.invoice_date),
    rows: data.rows.map(row => {
      return { ...row, totalText: row.total_text, total: MoneyAmount.parse(row.total),
        amountText: row.amount_text, amount: MoneyAmount.parse(row.amount),
        unit: row.unit, itemPriceText: row.item_price_text, itemPrice: MoneyAmount.parse(row.item_price),
        discountText: row.discount_text, discount: MoneyAmount.parse(row.discount)
      }
    })
  }
}

function InvoiceHistoryRow(props) {
  return <Grid item container direction="row" justify="flex-end">
    <Grid item>
      <Typography>
        {moment(props.history.stamp).format('DD.MM.YYYY HH:mm')}: {props.history.recipients}
      </Typography>
    </Grid>
    <Grid item>
      {props.isPending && <CircularProgress size={20}/>}
      {!props.isPending && <DoneIcon style={{ color: green[500] }}/>}
    </Grid>
  </Grid>
}

function InvoiceHistory(props) {
  const [showFull, setShowFull] = useState(false)
  const DEFAULT_ITEMS_SHOWN = 3

  function toggleShowFull() {
    setShowFull(!showFull)
  }

  const shownItems = props.historyData.slice(0, showFull ? props.historyData.length : DEFAULT_ITEMS_SHOWN)

  return <Grid container direction="column" alignItems="flex-end">
      {props.pendingRecipients && <InvoiceHistoryRow isPending={true} history={{stamp: moment.utc().format('YYYY-MM-DD HH:mm:ss') + 'Z', 'recipients': props.pendingRecipients}}/>}

      {shownItems.map((row, index) => (
        <InvoiceHistoryRow key={row.id} history={row} isPending={false}/>
        )
      )}
    {props.historyData.length > DEFAULT_ITEMS_SHOWN && <Grid item><Button onClick={toggleShowFull}>{showFull ? 'Näita vähem' : 'Näita kõiki'}</Button></Grid>}
  </Grid>
}

function InvoiceRows(props) {
  const rows = props.rows
  const invoiceType = parseInt(props.invoiceType)
  const vatPercentage = props.vatPercentage
  const isVATError = props.isVATError

  function setRows(rows) {
    props.onSetRows(rows)
  }

  function removeRow(rowNo) {
    const rowsCopy = rows.slice()
    rowsCopy.splice(rowNo, 1)
    setRows(rowsCopy)
    props.showSnackInfo('Rida kustutatud', () => {
      setRows(rows)
    })
  }

  function createEmptyRow() {
    return {
      code: '',
      description: '',
      totalText: (invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) ? MoneyAmount.ZERO().toString() : '',
      total: MoneyAmount.ZERO(),
      amountText: '',
      amount: MoneyAmount.ZERO(),
      unit: '',
      itemPrice: MoneyAmount.ZERO(),
      itemPriceText: '',
      discount: MoneyAmount.ZERO(),
      discountText: '',
    }
  }

  function addRow() {
    setRows(rows.concat(createEmptyRow()))
  }

  function setRowData(row, data) {
    const rowsCopy = rows.slice()
    const rowData = { ...rowsCopy[row] }
    const fields = ['code', 'description', 'totalText', 'amountText', 'unit', 'itemPriceText', 'discountText'];
    fields.forEach(field => {
      if (typeof data[field] !== 'undefined') {
        rowData[field] = data[field]
        if (field === 'totalText') {
          rowData.total = MoneyAmount.parse(data[field])
        } else if (field === 'amountText') {
          rowData.amount = MoneyAmount.parse(data[field])
        } else if (field === 'itemPriceText') {
          rowData.itemPrice = MoneyAmount.parse(data[field])
        } else if (field === 'discountText') {
          //todo: add validation to be not bigger than 100
          rowData.discount = MoneyAmount.parse(data[field])
        }
      }
    })
    if (invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) {
      if (isNaN(rowData.itemPrice) || isNaN(rowData.amount) || isNaN(rowData.discount)) {
        rowData.total = MoneyAmount.ZERO()
        rowData.totalText = ''
      } else {
        const total = rowData.itemPrice.times(rowData.amount)
        const discount = total.times(rowData.discount.times(new MoneyAmount(0, 1)))
        rowData.total = total.minusOrToZero(discount)
        rowData.totalText = rowData.total.toString()
      }
    }
    rowsCopy[row] = rowData
    setRows(rowsCopy)
  }

  return (
    <TableContainer>
      <Table stickyHeader style={{ tableLayout: "auto" }}>
        <TableHead>
          {invoiceType === EST_INVOICE_TYPE &&
          <TableRow>
            <TableCell>Kauba kood</TableCell>
            <TableCell>Kauba nimetus</TableCell>
            <TableCell colSpan={2}>Summa</TableCell>
          </TableRow>}
          {invoiceType === FIN_INVOICE_TYPE &&
          <TableRow>
            <TableCell>Kauba nimetus</TableCell>
            <TableCell>Kogus</TableCell>
            <TableCell>Ühik</TableCell>
            <TableCell>Tüki hind</TableCell>
            <TableCell>Soodustus</TableCell>
            <TableCell colSpan={2}>Summa</TableCell>
          </TableRow>}
          {invoiceType === EST_INVOICE_TYPE_2 &&
            <TableRow>
              <TableCell>Kauba nimetus</TableCell>
              <TableCell>Kogus</TableCell>
              <TableCell>Ühik</TableCell>
              <TableCell>Tüki hind</TableCell>
              <TableCell colSpan={2}>Summa</TableCell>
            </TableRow>}
        </TableHead>
        <TableBody>
          {rows.map((row, no) => (
            <InvoiceRow key={no} rowData={row} invoiceType={invoiceType}
                        rowNumber={no}
                        removeRow={() => {
                          removeRow(no)
                        }}
                        onDataUpdate={(data) => {
                          setRowData(no, data)
                        }} />
          ))}
          <TableRow>
            <TableCell>
              <Button onClick={() => {
                addRow()
              }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
              >
                Rida
              </Button>

            </TableCell>
            {invoiceType === FIN_INVOICE_TYPE && <TableCell colSpan={3}/>}
            {invoiceType === EST_INVOICE_TYPE_2 && <TableCell colSpan={2}/>}
            <TableCell align="right">Kokku</TableCell>
            {(invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) && <TableCell align="right">{props.total.toString()}</TableCell>}
            {(invoiceType === FIN_INVOICE_TYPE || invoiceType === EST_INVOICE_TYPE_2) && <TableCell/>}
            {invoiceType === EST_INVOICE_TYPE && <TableCell colSpan={2}>{props.total.toString()}</TableCell>}
          </TableRow>
          {invoiceType === EST_INVOICE_TYPE_2 && <TableRow>
            <TableCell colSpan={3} />
            <TableCell align="right">
              <TextField
                label="Käibemaksu %" variant="filled"
                autoComplete="off"
                error={isVATError} helperText={isVATError ? 'Palun sisestage täisarv' : null}
                value={vatPercentage}
                onChange={(e) => props.onVATPercentageUpdated(e.target.value)}
              />
            </TableCell>
            <TableCell align="right">{props.totalVAT.toString()}</TableCell>
            <TableCell/>
          </TableRow>}
          {invoiceType === EST_INVOICE_TYPE_2 && <TableRow>
            <TableCell colSpan={3} />
            <TableCell align="right">Tasuda</TableCell>
            <TableCell align="right"><b>{props.totalWithVAT.toString()}</b></TableCell>
            <TableCell/>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Invoice(props) {
  const openedInvoiceParam = props.match.params.invoiceId
  const companyId = props.match.params.companyId
  const isCopy = openedInvoiceParam.startsWith('copy')
  const openedInvoiceId = isCopy ? openedInvoiceParam.substr(4) : openedInvoiceParam
  const [invoiceId, setInvoiceId] = useState(isCopy ? 0 : props.match.params.invoiceId)
  const [isEmailSendingDialogOpen, setIsEmailSendingDialogOpen] = useState(false)
  const [emailSubject, setEmailSubject] = useState('')
  const [fileName, setFileName] = useState('')
  const [emailTo, setEmailTo] = useState('')
  const [sendBccToMyself, setSendBccToMyself] = useState(true)
  const [emailSenderAddress, setEmailSenderAddress] = useState('')
  const [emailText, setEmailText] = useState('')
  const [hasClickedSendOnEmailDialog, setHasClickedSendOnEmailDialog] = useState(false)
  const { googleUser, isSignedIn, isInitialized } = useAuth()
  const [pendingEmailRecipients, setPendingEmailRecipients] = useState(false)

  const [invoiceData, setInvoiceData, loadingError] = useGet(
    googleUser,
    serverUrlPrefix + '?viewInvoiceId=' + openedInvoiceId + '&copy=' + (isCopy ? '1' : '0') + '&companyId=' + companyId,
    null,
    invoiceNormalizer
  )

  useEffect(() => {
    if (invoiceData && invoiceData.company) {
      saveLastSelectedCompanyToLocalStorage(invoiceData.company.id)
    }
  }, [invoiceData])

  function updateInvoiceData(field, value) {
    setInvoiceData({ ...invoiceData, [field]: value })
  }

  const [snackbarInfoIsOpen, setSnackbarInfoIsOpen] = useState(false)
  const [snackUndoAction, setSnackUndoAction] = useState(null)
  const [lastInfoText, setLastInfoText] = useState(null)
  const [lastInfoSeverity, setLastInfoSeverity] = useState(null)
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null)
  const rows = invoiceData ? invoiceData.rows : []
  const [snackCounter, setSnackCounter] = useState(0)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [confirmDialogText, setConfirmDialogText] = useState(null)
  const [confirmDialogOkAction, setConfirmDialogOkAction] = useState(null)
  const [dialogCounter, setDialogCounter] = useState(0)
  const [isDownloadPdfSnackOpen, setIsDownloadPdfSnackOpen] = useState(false)
  const [openedPdfUrl, setOpenedPdfUrl] = useState(null)
  const history = useHistory()

  function openHome() {
    history.push(appUrlPrefix)
  }

  function showSnack(text, severity, undoAction = null) {
    setLastInfoText(text)
    setLastInfoSeverity(severity)

    if (undoAction) {
      setSnackUndoAction(() => () => {
        setSnackUndoAction(null)
        setSnackbarInfoIsOpen(false)
        undoAction()
      })
    } else {
      setSnackUndoAction(null)
    }
    setSnackCounter(snackCounter + 1)
    setSnackbarInfoIsOpen(true)
  }

  function showSnackInfo(text, undoAction = null) {
    showSnack(text, 'info', undoAction)
  }

  function showSnackError(text) {
    showSnack(text, 'error')
  }

  function showSnackSuccess(text) {
    showSnack(text, 'success')
  }

  function setRows(rows) {
    updateInvoiceData('rows', rows)
  }

  function setVATPercentage(val) {
    updateInvoiceData('vat_percentage', val)
  }

  function getTotal() {
    let total = new MoneyAmount(0, 0)
    rows.forEach(row => {
        total = total.add(row.total)
      }
    )
    return total
  }

  const total = getTotal()
  const vatPercentage = invoiceData ? parseInt(invoiceData.vat_percentage) : NaN
  const isVATError = isNaN(vatPercentage) || vatPercentage < 0 || vatPercentage >= 100 || vatPercentage.toString() !== invoiceData.vat_percentage.toString()
  const totalVAT = isVATError ? '' : total.findVATAmount(vatPercentage)
  const totalWithVAT = isVATError ? '' : total.add(totalVAT)

  function addDays(date, days) {
    const result = new Date(Number(date))
    result.setDate(result.getDate() + parseInt(days))

    return result;
  }

  function getInvoiceDueDate() {
    return invoiceData ? addDays(invoiceData.invoice_date, invoiceData.due_days) : null
  }


  const [userData, setUserData] = useGlobalState(USER_DATA_GLOBAL_STATE_KEY)

  const replaceUserCompanies = (companies) =>
  {
    setUserData({ ...userData, companies: companies })
  }

  function deleteInvoice() {
    setConfirmDialogText('Kas arve ' + invoiceData.invoice_number + ' kustutada?')
    setConfirmDialogOkAction(() => () => {
      axios.post(serverUrlPrefix + '?delete=' + invoiceId, null, {
        headers: buildAuthHeaders(googleUser)
      })
        .then(function (response) {
          replaceUserCompanies(response.data.companies)
          openAppUrl(history, '')
        })
    })
    setDialogCounter(dialogCounter + 1)
    setIsDialogOpen(true)
  }

  function doSave(onCompleted) {
    let hasError = false
    const rowsJson = rows.map(row => {
      if (isNaN(row.total) || isNaN(row.amount) || isNaN(row.itemPrice) || isNaN(row.discount)) {
        hasError = true
      }
      return {
        code: row.code,
        description: row.description,
        total: row.total.toString(),
        total_text: row.totalText,
        amount: row.amount.toString(),
        amount_text: row.amountText,
        unit: row.unit,
        item_price: row.itemPrice.toString(),
        item_price_text: row.itemPriceText,
        discount: row.discount.toString(),
        discount_text: row.discountText,
      }
    })
    if (isNaN(total)) {
      hasError = true
    }
    if (isVATError) {
      hasError = true
    }
    let invoiceDateFormatted = invoiceData.invoice_date
    if (invoiceDateFormatted instanceof Date && !isNaN(invoiceDateFormatted)) {
      invoiceDateFormatted = moment(invoiceDateFormatted).format('YYYY-MM-DD')
    } else {
      hasError = true
    }
    let dueDate = getInvoiceDueDate()
    if (dueDate instanceof Date && !isNaN(dueDate)) {
      dueDate = moment(dueDate).format('YYYY-MM-DD')
    } else {
      hasError = true
    }
    if (hasError) {
      showSnackError('Viga vormil, palun parandage!')
    } else {
      const data = {
        id: invoiceId,
        invoiceNumber: invoiceData.invoice_number,
        rows: rowsJson,
        total: total.toString(),
        invoiceDate: invoiceDateFormatted,
        dueDate: dueDate,
        dueDays: invoiceData.due_days,
        payerName: invoiceData.payer_name,
        payerAddress: invoiceData.payer_address,
        description: invoiceData.description,
        companyId: companyId,
        invoiceType: invoiceData.invoice_type,
        vatPercentage,
        totalVAT: totalVAT.toString(),
        totalWithVAT: totalWithVAT.toString()
      }
      if (invoiceData.copiedInvoiceId) {
        data.copiedInvoiceId = invoiceData.copiedInvoiceId
      }
      axios.post(serverUrlPrefix + '?save=1&giveCompanies=1', data, {
        headers: buildAuthHeaders(googleUser)
      })
        .then(function (response) {
          const invoiceData = response.data.invoice
          const id = invoiceData.id
          if (id !== invoiceId) {
            openInvoicePage(history, invoiceData.company.id, id)
          }
          setInvoiceId(id)
          replaceUserCompanies(response.data.companies)
          onCompleted(invoiceData)
        })
        .catch(error => {
          showSnackError('Salvestamine ei õnnestunud: ' + error)
        });
    }
  }

  function closeSaveSuccess() {
    setSnackbarInfoIsOpen(false)
  }

  function save() {
    doSave(() => {
      showSnackSuccess('Salvestatud!')
    })
  }

  function toPdf() {
    doSave((invoice) => {
      const url = serverUrlPrefix + '?pdf=' + invoice.id + '&uuid=' + invoice.uuid
      const opened = window.open(url)
      if (!opened) {
        setOpenedPdfUrl(url)
        setIsDownloadPdfSnackOpen(true)
      }
    })
  }

  function openEmailDialog() {
    doSave((invoice) => {
      setHasClickedSendOnEmailDialog(false)
      setFileName(invoice.fileName)
      setEmailSubject(invoice.emailSubject)
      setEmailTo(invoice.emailTo)
      setEmailText(invoice.emailText)
      setSendBccToMyself(invoice.isSendBcc)
      setEmailSenderAddress(invoice.emailSenderAddress)
      setIsEmailSendingDialogOpen(true)
    })
  }

  function closeEmailDialog() {
    setIsEmailSendingDialogOpen(false)
  }

  function getEmailSubjectError(forceCheck = false) {
    if ((forceCheck || hasClickedSendOnEmailDialog) && emailSubject.trim().length === 0) {
      return 'Palun sisestage pealkiri!'
    } else {
      return null
    }
  }

  function getEmailToError(forceCheck = false) {
    if ((forceCheck || hasClickedSendOnEmailDialog) && emailTo.trim().length === 0) {
      return 'Palun sisestage e-maili saaja!'
    } else {
      return null
    }
  }

  function sendEmail() {
    setHasClickedSendOnEmailDialog(true)
    if (getEmailSubjectError(true) || getEmailToError(true)) {
      showSnackError('Viga vormil!')
    } else {
      setPendingEmailRecipients(emailTo)
      axios.post(serverUrlPrefix + '?email=1', {
        id: invoiceId,
        subject: emailSubject,
        to: emailTo.split(','),
        text: emailText,
        sendBcc: sendBccToMyself
      }, {
        headers: buildAuthHeaders(googleUser)
      })
        .then(function (response) {
          setPendingEmailRecipients(false)
          showSnackSuccess('Email on saadetud, võib hakata laekumist ootama :)')
          updateInvoiceData('history', response.data.history)
        })
        .catch(error => {
          setPendingEmailRecipients(false)
          if (error.response && error.response.data && error.response.data.error) {
            showSnackError(error.response.data.error)
          } else {
            showSnackError('Saatmine ei õnnestunud: ' + error)
          }
        });
      closeEmailDialog()
    }
  }

  function closeMoreActionsMenu() {
    setMoreActionsAnchorEl(null)
  }

  function openMoreActionsMenu(event) {
    setMoreActionsAnchorEl(event.currentTarget)
  }

  function changeableInvoiceFieldValue(field) {
    return {
      value: invoiceData[field],
      onChange: (e) =>
        updateInvoiceData(field, e.target.value)
    }
  }

  function openCopy() {
    doSave(() => {
      openInvoiceCopy(history, companyId, invoiceId)
      setInvoiceId(0)
    })
  }

  function preventDefaultAndOpenHome(event) {
    event.preventDefault()
    openHome()
  }

  const invoiceDueDate = moment(getInvoiceDueDate()).format('DD.MM.YYYY')

  const snackAction = snackUndoAction ? <Button color="inherit" size="small" onClick={() => snackUndoAction()}>
    Pane tagasi
  </Button> : null

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={etLocale}>
      <div>

        <Dialog
          key={'dialog' + dialogCounter}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmDialogText}</DialogTitle>

          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="primary">
              Loobu
            </Button>
            <Button onClick={() => {setIsDialogOpen(false); confirmDialogOkAction()}} color="primary" autoFocus>
              Kustuta
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar key={'snack1' + snackCounter} open={snackbarInfoIsOpen} autoHideDuration={6000} onClose={() => closeSaveSuccess()}>
          <Alert onClose={() => closeSaveSuccess()} severity={lastInfoSeverity}
                 action={snackAction}>
            {lastInfoText}
          </Alert>
        </Snackbar>
        <Snackbar key={'snack2' + snackCounter} open={isDownloadPdfSnackOpen} autoHideDuration={6000} onClose={() => setIsDownloadPdfSnackOpen(false)}>
          <Alert onClose={() => setIsDownloadPdfSnackOpen(false)} severity="info">
            Pdf-i kuvamiseks lubage pop-upid või klikake <a href={openedPdfUrl} target="_blank" rel="noopener noreferrer">siia</a>
          </Alert>
        </Snackbar>

        <HeaderBar title={'Arvemasin'} companyId={companyId}/>
        <Grid container direction="column" style={{ padding: '2rem' }}>
          {isInitialized && !isSignedIn && <Grid item><LoginButton/></Grid>}
          {isInitialized && isSignedIn && <Grid item container direction="row">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href={appUrlPrefix} onClick={preventDefaultAndOpenHome}>
                Arved
              </Link>
              {invoiceData && !isCopy &&
              <Typography color="textPrimary">{invoiceData.company.name} Arve {invoiceData.invoice_number}</Typography>
              }
              {invoiceData && isCopy &&
              <Typography color="textPrimary">{invoiceData.company.name} Koopia arvest {invoiceData.original_invoice_number}</Typography>
              }
            </Breadcrumbs>
          </Grid>}
          {loadingError &&
          <LoadingError error={loadingError.toString()}/>
          }
          {invoiceData && <Grid item container direction="column">
            <Grid item container direction="row" justify="flex-end" spacing={1}>
              <Grid item>
                <Button
                  onClick={() => {
                    save()
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Salvesta
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    toPdf()
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<PrintIcon />}
                >
                  Ava PDF
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    openEmailDialog()
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<EmailIcon />}
                >
                  Saada
                </Button>
                <Dialog open={isEmailSendingDialogOpen} onClose={closeEmailDialog} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">E-mail</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="emailSubject"
                      label="Pealkiri"
                      fullWidth
                      value={emailSubject}
                      error={getEmailSubjectError() !== null} helperText={getEmailSubjectError()}
                      onChange={(e) => {setEmailSubject(e.target.value)}}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="emailRecepient"
                      label="Saaja(d):"
                      type="email"
                      fullWidth
                      value={emailTo}
                      error={getEmailToError() !== null} helperText={getEmailToError()}
                      onChange={(e) => {setEmailTo(e.target.value)}}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={sendBccToMyself} color="primary" onChange={(e) => {setSendBccToMyself(e.target.checked)}}/>}
                      label={'Saada pimekoopia ' + emailSenderAddress}
                    />
                    <TextField style={{ width: "100%" }}
                               label="Kirja sisu:"
                               margin="normal"
                               multiline
                               rows={3}
                               rowsMax={6}
                               value={emailText}
                               onChange={(e) => {setEmailText(e.target.value)}}
                    />
                    <Grid container>
                      <Grid item/>
                      <Grid item>
                          <Chip
                            icon={<AttachFileIcon />}
                            label={fileName}
                            onClick={toPdf}
                            variant="outlined"
                          />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeEmailDialog} color="secondary">
                      Loobu
                    </Button>
                    <Button onClick={sendEmail} color="primary">
                      Saada
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              {!isCopy && <Grid item>
                <IconButton color="primary" label="add to shopping cart" onClick={(event) => openMoreActionsMenu(event)}>
                  <MoreIcon />
                </IconButton>
                <Menu
                  anchorEl={moreActionsAnchorEl}
                  keepMounted
                  open={Boolean(moreActionsAnchorEl)}
                  onClose={() => closeMoreActionsMenu()}
                >
                  {!isCopy && invoiceId > 0 && <MenuItem>
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kopeeri uueks arveks" onClick={() => {
                      closeMoreActionsMenu()
                      openCopy()
                    }
                    } />
                  </MenuItem>}
                  <MenuItem>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kustuta" onClick={() => {
                      closeMoreActionsMenu()
                      deleteInvoice()
                    }}/>
                  </MenuItem>
                  <MenuItem onClick={() => closeMoreActionsMenu()}>Loobu</MenuItem>
                </Menu>
              </Grid>
              }
            </Grid>

            {(invoiceData.history.length > 0 || pendingEmailRecipients) && <Grid container>
              <InvoiceHistory historyData={invoiceData.history} pendingRecipients={pendingEmailRecipients}/>
            </Grid>}

            <Grid
              container direction="row"
            >
              <Grid item container direction="column" xs={6}>
                <Grid item container md={6} xs={12} direction="column" spacing={1}>
                  <Grid item>
                    <TextField style={{ width: "100%" }}
                               variant="filled"
                               label="Maksja nimi:"
                               {...changeableInvoiceFieldValue('payer_name')}
                    />
                  </Grid>
                  <Grid item>
                    <TextField style={{ width: "100%" }}
                               variant="filled"
                               label="Maksja aadress:"
                               multiline
                               rows={2}
                               rowsMax={3}
                               {...changeableInvoiceFieldValue('payer_address')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="row" xs={6}>
                <Grid item container md={5} xs={8} direction="column" spacing={1}>
                  <Grid item>
                    <TextField style={{ width: "100%" }}
                               label="Arve number" variant="filled"
                               autoComplete="off"
                               {...changeableInvoiceFieldValue('invoice_number')}
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker style={{ width: "100%" }}
                                        disableToolbar
                                        variant="inline"
                                        autoOk={true}
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="date-picker-inline"
                                        label="Arve kuupäev:"
                                        value={invoiceData.invoice_date}
                                        onChange={(date) => updateInvoiceData('invoice_date', date)}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField style={{ width: "100%" }}
                               label="Päevi maksmiseks:" variant="filled"
                               autoComplete="off"
                               {...changeableInvoiceFieldValue('due_days')}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={4} justify="flex-end">
                  <Grid style={{ marginLeft: 10 }}>
                    <TextField disabled={true} style={{ width: "100%" }}
                               label="Maksetähtaeg:" variant="filled"
                               autoComplete="off"
                               value={invoiceDueDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField style={{ width: "100%" }}
                         variant="filled"
                         label="Kirjeldus, mille eest on arve:"
                         margin="normal"
                         multiline
                         rowsMax={3}
                         {...changeableInvoiceFieldValue('description')}
              />
            </Grid>
            <Grid item>
              <InvoiceRows rows={invoiceData.rows}
                           total={total}
                           vatPercentage={invoiceData.vat_percentage}
                           isVATError={isVATError}
                           totalVAT={totalVAT}
                           totalWithVAT={totalWithVAT}
                           invoiceType={invoiceData.invoice_type}
                           showSnackInfo={(text, undoAction) => showSnackInfo(text, undoAction)}
                           onSetRows={(rows) => {
                             setRows(rows)
                           }}
                           onVATPercentageUpdated={(newVal) => {
                             setVATPercentage(newVal)
                           }}
              />
            </Grid>
          </Grid>}
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
}

function InvoiceCard(props) {

  const cardStyles = makeStyles({
    root: {
      width: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = cardStyles();
  const invoice = props.invoice
  const history = useHistory()

  const openInvoice = () => {
    openInvoicePage(history, invoice.companyId, invoice.id);
  }

  const copyInvoice = () => {
    openInvoiceCopy(history, invoice.companyId, invoice.id)
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {invoice.payer_name}
            </Typography>
            <Typography variant="h5" component="h2">
              {invoice.invoice_number}
            </Typography>

            <Typography variant="body2" component="p">
              {moment(invoice.invoice_date).format('DD.MM.YYYY')} - {invoice.total_with_vat}€
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {invoice.description}
            </Typography>
          </Grid>
          {invoice.is_sent == 1 && <Grid item>
            <DoneIcon style={{ color: green[500] }}/>
          </Grid>}
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={openInvoice} startIcon={<EditIcon />}>Vaata</Button>
        <Button size="small" onClick={copyInvoice} startIcon={<CopyIcon />}>Kopeeri</Button>
      </CardActions>
    </Card>
  );
}

function LoadingError(props) {

  const reload = () => {
    window.location.reload()
  }

  return <Grid container direction="column">
    <Grid item>
      <Typography variant="h6" gutterBottom>
        Päring ebaõnnestus
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h6" gutterBottom>
        {props.error}
      </Typography>
    </Grid>
    <Grid item>
      <Button onClick={reload}>Lae uuesti</Button>
    </Grid>
  </Grid>
}

const createContext = () => {
  const ctx = React.createContext(undefined)

  const useCtx = () => {
    const contextValue = useContext(ctx)

    if (contextValue === undefined)
      throw new Error('useCtx must be inside a Provider with a value')

    return contextValue
  }

  return [useCtx, ctx.Provider]
}


const useAuthInternal = () => {
  const AUTH_TOKEN_KEY = 'authToken'

  const [userData, setUserData] = useGlobalState(USER_DATA_GLOBAL_STATE_KEY)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  const signIn = () => {
    setIsSignedIn(false)
    setUserData(null)
    const token = 'tmp' + new Date().getTime() + '-' + createUUID() + '-' + createUUID()
    localStorage.setItem(AUTH_TOKEN_KEY, token)
    window.location.replace(serverUrlPrefix + 'login/?token=' + token + '&redirectTo=' + encodeURI(window.location.pathname))
  }

  const signOut = () => {
    setIsSignedIn(false)
    setUserData(null)
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(AUTH_TOKEN_KEY)
    if (authToken) {
      return getQuery({token: authToken}, serverUrlPrefix + '?logOut=1', () => {
      }, () => {
      })
    }
  }

  useEffect(() => {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (authToken) {
      if (userData && userData.token == authToken) {
        setIsSignedIn(true)
        setIsInitialized(true)
      } else {
        setIsInitialized(false)
        setUserData(null)
        return getQuery({token: authToken}, serverUrlPrefix + '?getUser=1', (result) => {
            const gotUser = result.data.content
            localStorage.setItem(AUTH_TOKEN_KEY, gotUser.token)
            setUserData(gotUser)
            setIsSignedIn(true)
            setIsInitialized(true)
          }, (err) => {
            console.log('auth error, to not logged in state!', err)
            setIsSignedIn(false)
            setIsInitialized(true)
            setUserData(null)
            localStorage.removeItem(AUTH_TOKEN_KEY)
          }
        )
      }
    } else {
      setIsSignedIn(false)
      setUserData(null)
      setIsInitialized(true)
    }
  }, [isSignedIn, userData, setUserData])

  return {signIn, signOut, googleUser: userData, isSignedIn, isInitialized}
}

const [useAuth, AuthProvider] = createContext()

const AuthContext = ({ children }) => {
  var hookData = useAuthInternal()

  return <AuthProvider value={hookData}>{children}</AuthProvider>
}

const Footer = () => (
  <div className="footer">
    <p>v1.0.3</p>
  </div>
)

function LoginButton() {
  const { signIn } = useAuth()

  return <Button onClick={signIn}>Logi sisse</Button>
}


const NoInvoices = ({ reason }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="h6" component="p" gutterBottom>
          {reason}
        </Typography>
      </Paper>
    </Grid>
  );
}


function Home() {

  const { signIn, googleUser, isSignedIn, isInitialized } = useAuth()

  const [invoicesFilter, setInvoicesFilter] = useGlobalState(INVOICES_FILTER_DATA_GLOBAL_STATE_KEY)
  const selectedYears = invoicesFilter.years ?? []
  const [selectedCompany, setSelectedCompany] = useGlobalState(SELECTED_COMPANY_GLOBAL_STATE_KEY)
  const companies = googleUser ? googleUser.companies : []

  console.log('companies', companies)

  useEffect(() => {
    if (companies && companies.length > 0) {
      let didUpdate = false
      if (selectedCompany) {
        companies.map((company) => {
          if (company.id == selectedCompany.id) {
            setSelectedCompany(company)
            didUpdate = true
          }
        })
      }
      if (!didUpdate) {
        const preferredCompanyId = localStorage.getItem(LAST_SELECTED_COMPANY_ID_LOCAL_STORAGE_KEY)
        companies.map((company) => {
          if (company.id == preferredCompanyId) {
            setSelectedCompany(company)
            didUpdate = true
          }
        })
      }
      if (!didUpdate) {
        companies.map((company) => {
          if (company.is_last_active) {
            setSelectedCompany(company)
            didUpdate = true
          }
        })
      }
      if (!didUpdate) {
        setSelectedCompany(companies[0])
      }
    } else {
      setSelectedCompany(null)
    }
  }, [companies, selectedCompany])

  useEffect(() => {
    if (selectedCompany) {
      saveLastSelectedCompanyToLocalStorage(selectedCompany.id)
    }
  }, [selectedCompany])

  const [invoices, , loadingError] = useGet(
    googleUser,
    serverUrlPrefix + '?invoices=1&companyId=' + (selectedCompany? selectedCompany.id : 0) + '&years=' + selectedYears.join(','),
    []
  )


  const history = useHistory();

  const addInvoice = () => {
    openAppUrl(history, 'invoice/' + selectedCompany.id + '/0');
  }

  const onCompanySelected = (event) => {
    companies.map((company) => {
        if (company.id == event.target.value) {
          setSelectedCompany(company)
        }
    })
  }

  const handleYearChange = (event) => {
    const {
      target: { value },
    } = event;

    setInvoicesFilter(
      {
        ...invoicesFilter,
        years: typeof value === 'string' ? value.split(',') : value,
      }
    )
  }

  const buildYearOptions = () => {
    const currentYear = new Date().getFullYear()
    const options = []
    if (selectedCompany && selectedCompany.first_invoice_year && selectedCompany.first_invoice_year < currentYear) {
      for (let year = selectedCompany.first_invoice_year; year <= currentYear; year++) {
        options.push(year)
      }
    } else {
      options.push(currentYear)
    }
    selectedYears.forEach((year) => {
      if (options.indexOf(year) < 0) {
        options.push(year)
      }
    })

    return options
  }
  const yearOptions = buildYearOptions()

  const renderSelectedYear = (selected) => {
    let text = ''
    let lastYear = null
    let mustFinish = false
    selected.sort().forEach((year) => {
      if (year === lastYear + 1) {
        mustFinish = true
      } else {
        if (mustFinish) {
          text += ' - ' + lastYear
          mustFinish = false
        }
        if (text.length > 0) {
          text += ', '
        }
        text += year
      }
      lastYear = year
    })
    if (mustFinish) {
      text += ' - ' + lastYear
    }

    return text
  }

  const classes = useStyles();
  const selectedCompanyHasSomeInvoices = selectedCompany && selectedCompany.first_invoice_year


  return <div><HeaderBar title='Arvemasin' isHome='true' companyId={selectedCompany?.id}/>

    <Grid container direction="column" style={{ padding: '2rem' }}>
      {isInitialized && !isSignedIn && <Grid item>
        <LoginButton/>
      </Grid>}
      {isInitialized && isSignedIn && <Grid item container direction="column" spacing={2}>

        {companies && companies.length === 0 && <Grid item>
          <Typography variant="h6" gutterBottom>
            Ettevõtte lisamiseks palun kontakteeruge!
          </Typography>
        </Grid>}

        {companies && companies.length > 1 && selectedCompany && <Grid item>
          <FormControl variant="filled">
          <InputLabel>Ettevõte</InputLabel>
          <Select
            value={selectedCompany.id}
            onChange={onCompanySelected}
          >
            {companies.map((company) => {
              return (<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)
              }
              )
            }
          </Select>
        </FormControl>
        </Grid>}

        {selectedCompany && <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddIcon />}
            onClick={addInvoice}
          >
            Lisa arve
          </Button>
        </Grid>}

        <Grid item/>

        {invoices && selectedCompanyHasSomeInvoices &&
        <Grid item container direction="row">
          <Grid item>
            {selectedCompany && <FormControl variant="filled">
              <InputLabel>{selectedYears.length === 0 ? 'Palun vali aasta!' : 'Aasta'}</InputLabel>
              <Select multiple value={selectedYears} onChange={handleYearChange}
                      renderValue={renderSelectedYear} className={classes.minWidth}>
                <MenuItem disabled value="">
                  <em>Kuvatav(ad) aasta(d)</em>
                </MenuItem>
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    <Checkbox checked={selectedYears.indexOf(year) > -1} />
                    <ListItemText primary={year} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          </Grid>
        </Grid>
        }
        {invoices && invoices.length > 0 &&
        <Grid container item direction="row" spacing={2}>
          {invoices.map((invoice) => {
            return (
              <Grid item key={invoice.id}>
                <InvoiceCard invoice={invoice} />
              </Grid>
            )
          })}
        </Grid>
        }
        {invoices && invoices.length === 0 && selectedCompanyHasSomeInvoices &&
          <NoInvoices reason={selectedYears.length === 0 ? 'Palun valige aasta!' : 'Valitud kriteeriumitele ei vasta ükski arve!'}/>
        }
        {loadingError &&
        <Grid>
          <LoadingError error={loadingError.toString()}/>
        </Grid>
        }
      </Grid>}
    </Grid>
    <Footer/>
  </div>
}

function Users() {
  let { userId } = useParams();
  return <h2>Users_{userId}</h2>;
}

function CompanySettings(props) {

  const companyId = props.match.params.companyId

  const { googleUser, isSignedIn, isInitialized } = useAuth()

  const [snackCounter, setSnackCounter] = useState(0)
  const [snackbarInfoIsOpen, setSnackbarInfoIsOpen] = useState(false)
  const [lastInfoText, setLastInfoText] = useState(null)
  const [lastInfoSeverity, setLastInfoSeverity] = useState(null)

  const [company, setCompanyData, loadingError] = useGet(googleUser, serverUrlPrefix + '?getCompany=' + companyId, false)

  function updateCompanyData(field, value) {
    setCompanyData({ ...company, [field]: value })
  }

  function changeableCompanyFieldValue(field) {
    return {
      value: company[field],
      onChange: (e) =>
        updateCompanyData(field, e.target.value)
    }
  }

  function save() {
    axios.post(serverUrlPrefix + '?saveCompany=1', company, {
      headers: buildAuthHeaders(googleUser)
    })
      .then(function (response) {
        if (response.data.errorMessage) {
          showSnackError(response.data.errorMessage)
        } else {
          showSnackSuccess('Salvestatud!')
        }
      })
      .catch(error => {
        showSnackError('Salvestamine ei õnnestunud: ' + error)
      });
  }


  function closeSaveSuccess() {
    setSnackbarInfoIsOpen(false)
  }

  function showSnackSuccess(text) {
    showSnack(text, 'success')
  }

  function showSnackError(text) {
    showSnack(text, 'error')
  }

  function showSnack(text, severity) {
    setLastInfoText(text)
    setLastInfoSeverity(severity)
    setSnackCounter(snackCounter + 1)
    setSnackbarInfoIsOpen(true)
  }

  return <div>
    <Snackbar key={'snack1' + snackCounter} open={snackbarInfoIsOpen} autoHideDuration={6000} onClose={() => closeSaveSuccess()}>
      <Alert onClose={() => closeSaveSuccess()} severity={lastInfoSeverity}>
        {lastInfoText}
      </Alert>
    </Snackbar>
    <HeaderBar title='Arvemasin' companyId={companyId}/>
    <Grid container direction="column" style={{ padding: '2rem' }}>
      {isInitialized && !isSignedIn && <Grid item><LoginButton/></Grid>}
      {isInitialized && isSignedIn && <Grid item container direction="row">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Ettevõtte seaded</Typography>
        </Breadcrumbs>
      </Grid>}
      {loadingError &&
      <LoadingError error={loadingError.toString()}/>
      }
      {company && <Grid item container direction="column">
        <Grid item container direction="row" justify="flex-end" spacing={1}>
          <Grid item>
            <Button
              onClick={() => {
                save()
              }}
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Salvesta
            </Button>
          </Grid>
        </Grid>
      </Grid>
      }

      {company &&
      <Grid container direction="column">
        <Grid item>
          <Typography color="textPrimary">Üldadmed:</Typography>
        </Grid>
        <Grid item>
          <TextField style={{ width: "100%" }}
                     label="Ettevõtte nimi:" variant="filled"
                     {...changeableCompanyFieldValue('name')}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">Emaili saatmise seaded</Typography>
        </Grid>
        <Grid item>
          <TextField style={{ width: "100%" }}
                     label="Saatmise Gmaili konto:" variant="filled"
                     {...changeableCompanyFieldValue('gmail_account')}
          />
        </Grid>
        <Grid item>
          <TextField style={{ width: "100%" }}
                     label="Gmaili parool:" variant="filled"
                     type="password"
                     autoComplete="current-password"
                     {...changeableCompanyFieldValue('gmail_password')}
          />
        </Grid>
        <Grid item>
          <TextField style={{ width: "100%" }}
                     label="Emaili saatja nimi:" variant="filled"
                     {...changeableCompanyFieldValue('gmail_name')}
          />
        </Grid>
        <Grid item>
          Parooli genereerimiseks ava <a href="https://myaccount.google.com/apppasswords" target="_blank">Google Account passwords</a>
        </Grid>
      </Grid>
      }

    </Grid>

    <Footer/>
  </div>
}


// ========================================

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  minWidth: {
    minWidth: 200,
  },
}));

function HeaderBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const { signOut, googleUser, isSignedIn } = useAuth()

  const open = Boolean(anchorEl);

  const openHome = () => {
    history.push(appUrlPrefix)
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCompanySettings = () => {
    handleClose()
    openAppUrl(history, 'settings/' + props.companyId)
  }

  const handleLogOut = () => {
    handleClose()
    signOut()
    openHome()
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {!props.isHome &&
          <IconButton title="Avalehele" edge="start" color="inherit" aria-label="menu"
                      onClick={openHome} className={classes.menuButton}>
            <HomeIcon/>
          </IconButton>
          }
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          {isSignedIn && googleUser && <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar alt={googleUser.name} src={googleUser.picture}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={openCompanySettings}>Ettevõtte seaded</MenuItem>
              <MenuItem onClick={handleLogOut}>Logi välja</MenuItem>
            </Menu>
          </div>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}

HeaderBar.defaultProps = {
  isHome: false
}

function InvoiceApp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Switch>
        <Route path={appUrlPrefix + 'invoice/:companyId/:invoiceId'} component={Invoice} />
        <Route path="/users/:userId">
          <Users />
        </Route>
        <Route path={appUrlPrefix + 'settings/:companyId'} component={CompanySettings} />
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}


function buildAuthHeaders(googleUser) {
  if (googleUser) {
    return {
      Authorization: googleUser.token
    }
  } else {
    return {}
  }
}

function getQuery(googleUser, url, onResult, onError) {
  const cancelToken = axios.CancelToken.source()
  if (googleUser && !url.includes('companyId=0')) {
    if (!googleUser.token) {
      console.log('a bit strange, token is not defined?')
    } else {
      axios.get(url, {
        cancelToken: cancelToken.token,
        headers: buildAuthHeaders(googleUser)
      })
        .then(function (response) {
          onResult(response)
        })
        .catch(function (thrown) {
          if (!axios.isCancel(thrown)) {
            onError(thrown)
          } else {
            console.log('it was cancelled!!!!')
          }
        })
    }
  }
  return () => {
    cancelToken.cancel()
  }
}

function useGet(googleUser, url, initialState, normalizer) {
  const [resultData, setData] = useState(initialState)
  const [resultError, setResultError] = useState(null)
  useEffect(() => {
    return getQuery(googleUser, url, (response) => {
      const content = response.data.content
      if (normalizer) {
        setData(normalizer(content))
      } else {
        setData(content)
      }
    }, (thrown) => {
      setResultError(thrown)
    })
  }, [googleUser, url, normalizer])
  return [resultData, setData, resultError]
}

function MainApp() {
  return (<Router>
    <InvoiceApp />
  </Router>);
}

ReactDOM.render(
  <AuthContext>
    <MainApp />
  </AuthContext>,
  document.getElementById('root')
);
